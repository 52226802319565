const defaultLang = "zh-tw";

async function getAssetName() {
  let files = await fetch("/asset-manifest.json").then(raw => raw.json())
    .then(manifest => manifest.files ? manifest.files : [])
    .catch(err => {
      console.error(err);
      return []
    });

  let lang = window.localStorage.getItem("tsaitung-lang") || defaultLang;
  let href = Object.values(files).find(s => s.includes(`language/${lang}`));
  if (href) {
    return href;
  } else {
    return `/language/${lang}.json`
  }
};

getAssetName().then(href => {
  if (href) {
    var link = document.createElement("link");
    link.href = href;
    link.rel = "prefetch";
    link.as = "fetch";
    document.head.appendChild(link);
  };
}).catch(console.error);
